import React from 'react'
import tw from 'twin.macro'
import Navbar from '../components/elements/Navbar'
import { Headline, Section, Subtitle } from './datenschutz'
import components from '../content/common'
import Footer from '../components/elements/Footer'

const IndexPage = () => {
  return (
    <>
      <Navbar content={components.navbar} />
      <Section>
        <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
          <Headline>Impressum</Headline>
          <Subtitle>Impressum</Subtitle>
          <p>
            Privatbrauerei ERDINGER Weißbräu Werner Brombach GmbH
            <br />
            Lange Zeile 1 und 3
            <br />
            85435 Erding
            <br />
            Telefon: <a href="tel:(0 81 22) 409 0">(0 81 22) 409 0</a>
            <br />
            Telefax: <a href="fax:(0 81 22) 409 115">(0 81 22) 409 115</a>
          </p>
          <p>
            Sitz der Gesellschaft
            <br />
            Erding/OBB
            <br />
            85435 Erding
            <br />
            Amtsgericht München HRB 86831
          </p>
          <p>
            Ust-Ident-Nummer:
            <br />
            DE 811 220 486
          </p>
          <p>
            Inhaber
            <br />
            Werner Brombach
          </p>
          <p>
            Geschäftsführer
            <br />
            Dr. Stefan Kreisz - Vorsitzender der Geschäftsführung
            <br />
            Dr. Stefan Huckemann
            <br />
            Josef Westermeier
            <br />
            Wolfgang Kuffner
          </p>
          <p>
            betreuende Internetagentur
            <br />
            Catenate GmbH
            <br />
            Schleißheimer Straße 156 Rgb.
            <br />
            80797 München
            <br />
            <a tw="" href="https://www.catenate.com" target="_blank">
              www.catenate.com
            </a>
            <br />
          </p>
          <p>
            <Subtitle>Disclaimer</Subtitle>
            <br />
            <b>Inhalte</b>
            <br />
            Der Inhalt dieser Seiten wurde sorgfältig bearbeitet und überprüft.
            ERDINGER Weißbräu übernimmt jedoch keine Gewähr für die Aktualität,
            Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten
            Informationen. Haftungsansprüche gegen ERDINGER Weißbräu, die sich
            auf Schäden materieller oder ideeller Art beziehen, welche durch die
            Nutzung oder Nichtnutzung der dargebotenen Informationen oder durch
            fehlerhafte und unvollständige Informationen verursacht wurden, sind
            grundsätzlich ausgeschlossen, sofern seitens ERDINGER Weißbräu kein
            nachweislich vorsätzliches oder grob fahrlässiges Verschulden
            vorliegt. ERDINGER Weißbräu behält sich ausdrücklich das Recht vor,
            Teile der Seite oder das gesamte Angebot ohne vorherige Ankündigung
            zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung
            einzustellen.
          </p>
          <p>
            <b>Urheber- und Markenrechte</b>
            <br />
            ERDINGER Weißbräu ist bestrebt, in allen Publikationen geltende
            Urheberrechte zu beachten. Sollte es trotzdem zu einer
            Urheberrechtsverletzung kommen, wird ERDINGER Weißbräu das
            entsprechende Objekt nach Benachrichtigung aus seiner Publikation
            entfernen bzw. mit dem entsprechenden Urheberrecht kenntlich machen.
            Alle innerhalb des Internetangebots genannten und gegebenenfalls
            durch Dritte geschützten Marken- und Warenzeichen unterliegen
            uneingeschränkt den Bestimmungen des jeweils gültigen
            Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
            Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss
            zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
            sind. Das Urheberecht für die eigenen Inhalte von ERDINGER Weißbräu
            steht allein ERDINGER Weißbräu zu. Eine Vervielfältigung solcher
            Grafiken, Sounds oder Texte in anderen elektronischen oder
            gedruckten Publikationen ist ohne ausdrückliche Zustimmung von
            ERDINGER Brauhaus nicht gestattet.
          </p>
          <p>
            <Subtitle>Nutzungsbedingungen</Subtitle>
            <br />
            ERDINGER Weißbräu ist mit angemessener Sorgfalt vorgegangen, um
            sicherzustellen, dass alle Angaben, die auf dieser Homepage gemacht
            werden, zum Zeitpunkt ihrer Eingabe der Richtigkeit entsprechen;
            allerdings kann es immer zu unabsichtlichen und vereinzelten Fehlern
            kommen. Sollte dies einmal vorkommen, bittet die ERDINGER Weißbräu
            um Verständnis. ERDINGER Weißbräu macht keine Angaben oder
            Zusicherungen zu den Informationen, die auf dieser Homepage zur
            Verfügung gestellt werden und behält sich das Recht vor, jederzeit
            ohne vorherige Ankündigung Änderungen und Korrekturen vorzunehmen.
            ERDINGER Weißbräu übernimmt keine Haftung für Ungenauigkeiten oder
            Auslassungen auf dieser Homepage, und ihre Besucher sind
            ausschließlich selbst für alle Entscheidungen verantwortlich, die
            aufgrund der in dieser Homepage enthaltenen Informationen getroffen
            werden. ERDINGER Weißbräu übernimmt im vollen gesetzlich zulässigen
            Umfang keinerlei Haftung für irgendwelche direkten, speziellen,
            indirekten Schäden, Folgeschäden oder Verluste, oder irgendwelche
            anderen Schäden oder Verluste gleich welcher Art, die, egal aus
            welchem Grund durch die Verwendung von Informationen entstehen, die
            direkt oder indirekt aus dieser Homepage entnommen wurden. Dies
            stellt keine Einschränkung Ihrer Rechte als Verbraucher nach
            Landesgesetz oder anderen Gesetzen dar, die nicht ausgeschlossen
            werden dürfen. Diese Homepage enthält Hypertext-Links zu anderen
            Webseiten, um es den Besuchern einfacher zu machen. Für diese
            fremden Inhalte ist ERDINGER Weißbräu nur dann verantwortlich, wenn
            ERDINGER Weißbräu von ihnen (d.h. auch von einem rechtswidrigen bzw.
            strafbaren Inhalt) positive Kenntnis hat und es ERDINGER Weißbräu
            technisch möglich und zumutbar ist, deren Nutzung zu verhindern. Bei
            Links handelt es sich allerdings stets um lebende (dynamische)
            Verweisungen. ERDINGER Weißbräu hat bei der erstmaligen Verknüpfung
            zwar den fremden Inhalt daraufhin überprüft, ob durch ihn eine
            mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit
            ausgelöst wird. ERDINGER Weißbräu ist aber nicht dazu verpflichtet,
            die Inhalte, auf die ERDINGER Weißbräu in seinem Angebot verweist,
            ständig auf Veränderungen zu überprüfen, die eine Verantwortlichkeit
            neu begründen könnten. ERDINGER Weißbräu besitzt das Urheberrecht
            für die Informationen und das Bildmaterial auf dieser Homepage. Das
            Kopieren von Seiten dieser Homepage für gewerbliche Zwecke ist
            grundsätzlich untersagt. Das Kopieren von Seiten dieser Homepage für
            nichtgewerbliche Zwecke wird nur dann genehmigt, wenn alle Kopien
            mit dem Copyright-Vermerk oder anderen Eigentumshinweisen und
            Haftungsausschlusserklärungen gekennzeichnet werden. Es werden keine
            weiteren Rechte für die Verwendung von Urheberrechten, Warenzeichen
            oder Patenten der ERDINGER Weißbräu vergeben. Bei den hier genannten
            Produktnamen handelt es sich um Warenzeichen von ERDINGER Weißbräu;
            hiervon ausgenommen sind Marken, die als Eigentum Dritter
            gekennzeichnet sind. Ein Hinweis auf ein Produkt auf dieser Website
            stellt kein Angebot zum Verkauf oder zur Lieferung dieses Produkts dar
            und bedeutet nicht, dass dieses Produkt in allen Ländern erhältlich ist
            oder dass der Name oder die Beschreibung des Produkts mit den Angaben auf
            der Website übereinstimmt.
          </p>
          <p>
            <b>Rechtswirksamkeit</b>
            <br />
            Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
            betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern
            Teile oder einzelne Formulierungen dieses Textes der geltenden
            Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
            sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt
            und ihrer Gültigkeit hiervon unberührt.
          </p>
        </div>
      </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default IndexPage
